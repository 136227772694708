<template>
  <main class="kb-main">
    <div class="main-content min-component mt-5">

      <div class="popup" id="popup-tuition-support-p012">
        <div class="popup-inner">
          <div class="popup-header"><h3 class="title">Secret</h3></div>
          <div class="popup-content mt-3">
            <div class="survey-container">
              <article class="popup-section section-form">
                <header class="section-header header-divider-v2">
                  <h4 class="title"></h4>
                </header>
                <div class="section-content">
                  <div class="kb-form-fields kb-form-fields-v2">
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label"><strong class="kb-form-label-text">Token</strong></label>
                      </div>
                      <div class="kb-form-column column-auto">
                        <input v-model.trim="params.acl" type="text" class="kb-form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="popup-buttons mt-3">
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="getAuthAcl"><span class="text">지원신청</span></button><!--v-if-->
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>

import {reactive} from 'vue';
import ApiService from '@/assets/js/api.service';
import {isSuccess} from '@/assets/js/util';
import {useStore} from 'vuex';
import {MUT_SET_SSL_ACL} from '@/store/modules/ssl/ssl-index';
import {useRouter} from 'vue-router';

export default {
  name: 'TestAuth',
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    let isLoading = false;
    const params = reactive({acl: ''});
    const goACLRoute = acl => {
      if (acl === 'AUTH_SSL') {
        store.commit(`ssl/${MUT_SET_SSL_ACL}`);
        setTimeout(() => {router.push({name: 'SSLHome'});}, 500);
      }
    };
    const getAuthAcl = () => {
      if (isLoading) return;
      if (params.acl) {
        isLoading = true;
        ApiService.post('/v1/app/auth/acl', params).
            then(res => {
              if (isSuccess(res) && res.acl) {goACLRoute(res.acl);} else {alert('올바른 토큰을 입력해주세요.');}
              isLoading = false;
            }).
            catch(() => {
              alert('올바른 토큰을 입력해주세요.');
              isLoading = false;
            });
      } else {alert('토큰을 입력해주세요.');}
    }
    return {params,getAuthAcl};
  },
};
</script>
